export const initialKeyboard = [
    { value: 'q', status: ''},
    { value: 'w', status: ''},
    { value: 'e', status: ''},
    { value: 'r', status: ''},
    { value: 't', status: ''},
    { value: 'y', status: ''},
    { value: 'u', status: ''},
    { value: 'i', status: ''},
    { value: 'o', status: ''},
    { value: 'p', status: ''},
    { value: 'a', status: ''},
    { value: 's', status: ''},
    { value: 'd', status: ''},
    { value: 'f', status: ''},
    { value: 'g', status: ''},
    { value: 'h', status: ''},
    { value: 'j', status: ''},
    { value: 'k', status: ''},
    { value: 'l', status: ''},
    { value: 'Backspace', status: ''},
    { value: 'z', status: ''},
    { value: 'x', status: ''},
    { value: 'c', status: ''},
    { value: 'v', status: ''},
    { value: 'b', status: ''},
    { value: 'n', status: ''},
    { value: 'm', status: ''},
    { value: 'Enter', status: ''},
  ];