export const puzzle_words = [
  "aaien",
  "aaide",
  "aapje",
  "aarde",
  "aards",
  "abces",
  "abdij",
  "abuis",
  "acryl",
  "actie",
  "actor",
  "acuut",
  "adder",
  "ademt",
  "adept",
  "aders",
  "adieu",
  "adres",
  "afdak",
  "afgod",
  "afrit",
  "afsla",
  "afval",
  "afwas",
  "afzag",
  "afzet",
  "afzie",
  "agaat",
  "agave",
  "agent",
  "ahorn",
  "aioli",
  "airco",
  "ajuin",
  "akela",
  "akker",
  "akten",
  "alarm",
  "album",
  "aldus",
  "alert",
  "algen",
  "aller",
  "alias",
  "alibi",
  "alien",
  "allen",
  "alles",
  "alpen",
  "alsof",
  "aluin",
  "amber",
  "ampel",
  "amper",
  "ampul",
  "amuse",
  "anaal",
  "anale",
  "ander",
  "angel",
  "angst",
  "anijs",
  "animo",
  "anjer",
  "anker",
  "annex",
  "anode",
  "aorta",
  "apart",
  "apert",
  "apneu",
  "appel",
  "april",
  "arena",
  "arend",
  "armen",
  "armer",
  "armoe",
  "armst",
  "aroma",
  "asbak",
  "asiel",
  "aster",
  "astma",
  "atjar",
  "atlas",
  "atoom",
  "audio",
  "audit",
  "avond",
  "azijn",
  "azuur",
  "baadt",
  "baalt",
  "baant",
  "baard",
  "baars",
  "bacil",
  "bacon",
  "baden",
  "badje",
  "bagel",
  "bajes",
  "baken",
  "baker",
  "bakje",
  "bakte",
  "balde",
  "balen",
  "balie",
  "balkt",
  "balts",
  "bands",
  "banen",
  "bange",
  "banjo",
  "bapao",
  "barak",
  "baren",
  "baret",
  "barok",
  "baron",
  "barre",
  "barse",
  "barst",
  "basis",
  "basta",
  "baten",
  "batik",
  "batje",
  "baton",
  "bazen",
  "bazig",
  "bazin",
  "bedek",
  "bedel",
  "beden",
  "bedje",
  "bedot",
  "beeft",
  "beeld",
  "beent",
  "beest",
  "begin",
  "begon",
  "behap",
  "beide",
  "beige",
  "beits",
  "bejag",
  "bekaf",
  "beken",
  "beker",
  "bekje",
  "belde",
  "beleg",
  "belet",
  "beman",
  "bemin",
  "benam",
  "bende",
  "benen",
  "benig",
  "benul",
  "benut",
  "beoog",
  "bepak",
  "beren",
  "bergt",
  "besef",
  "besje",
  "besla",
  "besta",
  "beste",
  "beten",
  "beter",
  "beton",
  "beukt",
  "beunt",
  "beurs",
  "beurt",
  "beval",
  "bevat",
  "bevel",
  "beven",
  "bever",
  "bezag",
  "bezat",
  "bezem",
  "bezet",
  "bezig",
  "bezin",
  "bezit",
  "bidet",
  "bidon",
  "biedt",
  "biels",
  "biest",
  "bijen",
  "bijna",
  "bindt",
  "bingo",
  "biopt",
  "bitje",
  "bivak",
  "bizar",
  "bizon",
  "blaag",
  "blaam",
  "blaar",
  "blaas",
  "blaat",
  "blaft",
  "blank",
  "blauw",
  "bleef",
  "bleek",
  "bleke",
  "bliep",
  "blies",
  "blije",
  "blijf",
  "blijk",
  "blind",
  "blink",
  "blits",
  "bloed",
  "bloei",
  "bloem",
  "bloes",
  "blogs",
  "blogt",
  "blokt",
  "blond",
  "blonk",
  "bloos",
  "bloot",
  "blowt",
  "blote",
  "blues",
  "bluft",
  "blust",
  "bocht",
  "bodem",
  "boden",
  "bodes",
  "boeit",
  "boekt",
  "boent",
  "boers",
  "boert",
  "boete",
  "bofte",
  "bogen",
  "bokje",
  "bokst",
  "bokte",
  "bolle",
  "bomen",
  "bonen",
  "bongo",
  "bonje",
  "bonkt",
  "bonst",
  "bonte",
  "bonus",
  "boord",
  "boort",
  "boost",
  "boren",
  "borst",
  "bosje",
  "bosui",
  "boten",
  "boter",
  "botje",
  "botox",
  "botst",
  "botte",
  "boude",
  "bouwt",
  "boven",
  "bowlt",
  "boxen",
  "boxer",
  "bozer",
  "bozig",
  "braad",
  "braaf",
  "braak",
  "braam",
  "bralt",
  "brand",
  "brave",
  "bravo",
  "brede",
  "breed",
  "breek",
  "brein",
  "breng",
  "breuk",
  "brief",
  "brink",
  "brits",
  "broed",
  "broei",
  "broek",
  "broer",
  "bromt",
  "brons",
  "brood",
  "broos",
  "brouw",
  "broze",
  "bruid",
  "bruin",
  "bruis",
  "brult",
  "brute",
  "bruto",
  "bruut",
  "bugel",
  "buggy",
  "buien",
  "buigt",
  "bukte",
  "bulkt",
  "buren",
  "busje",
  "buste",
  "buurt",
  "buxus",
  "bytes",
  "cacao",
  "cadet",
  "cakes",
  "cameo",
  "canon",
  "cargo",
  "casco",
  "casus",
  "cavia",
  "ceder",
  "cello",
  "chaos",
  "check",
  "chefs",
  "china",
  "chips",
  "choco",
  "choke",
  "cider",
  "circa",
  "citer",
  "claim",
  "clans",
  "clown",
  "clubs",
  "coach",
  "cobra",
  "cocon",
  "codes",
  "combi",
  "combo",
  "conus",
  "corps",
  "corso",
  "coupe",
  "coups",
  "cover",
  "crash",
  "credo",
  "curry",
  "cycli",
  "cyaan",
  "cyste",
  "daags",
  "daagt",
  "daalt",
  "dacht",
  "dadel",
  "daden",
  "dader",
  "dagen",
  "dagje",
  "daken",
  "dakje",
  "dalen",
  "daler",
  "damde",
  "dames",
  "dampt",
  "dandy",
  "danig",
  "dankt",
  "danst",
  "darts",
  "daten",
  "dates",
  "datum",
  "daver",
  "dazen",
  "deals",
  "dealt",
  "debat",
  "debet",
  "decor",
  "deden",
  "deels",
  "deelt",
  "deens",
  "deert",
  "degen",
  "deins",
  "deint",
  "deken",
  "dekje",
  "dekte",
  "delen",
  "deler",
  "delft",
  "delta",
  "demon",
  "dempt",
  "denen",
  "denkt",
  "depot",
  "depri",
  "depte",
  "derby",
  "derde",
  "deren",
  "derft",
  "desem",
  "detox",
  "deugd",
  "deugt",
  "deukt",
  "dezen",
  "dezer",
  "dicht",
  "dieet",
  "diens",
  "dient",
  "diepe",
  "diept",
  "dijen",
  "dikke",
  "dikst",
  "dikte",
  "dildo",
  "dille",
  "dimde",
  "diner",
  "dingo",
  "dingt",
  "diode",
  "dipje",
  "disco",
  "divan",
  "dixit",
  "djinn",
  "doden",
  "doder",
  "doelt",
  "doema",
  "doemt",
  "doffe",
  "dogma",
  "doken",
  "dokte",
  "dolen",
  "dolle",
  "domme",
  "domst",
  "donor",
  "donut",
  "doods",
  "doodt",
  "dooft",
  "dooie",
  "dooit",
  "doolt",
  "doopt",
  "doorn",
  "dopen",
  "doper",
  "dopje",
  "dorps",
  "dorst",
  "doses",
  "dosis",
  "dotje",
  "doven",
  "dozen",
  "draad",
  "draaf",
  "draag",
  "draai",
  "draak",
  "draal",
  "drain",
  "drama",
  "dramt",
  "drang",
  "drank",
  "dreef",
  "dreig",
  "drein",
  "drent",
  "dreun",
  "drift",
  "drijf",
  "drilt",
  "dring",
  "drink",
  "drive",
  "droef",
  "droeg",
  "droge",
  "drone",
  "drong",
  "dronk",
  "droog",
  "droom",
  "droop",
  "dropt",
  "drugs",
  "druif",
  "druip",
  "drukt",
  "drums",
  "drumt",
  "drupt",
  "duaal",
  "duels",
  "duffe",
  "dufst",
  "duidt",
  "duikt",
  "duimt",
  "duits",
  "duldt",
  "dummy",
  "dumpt",
  "dunkt",
  "dunne",
  "dunst",
  "duren",
  "durft",
  "dutje",
  "dutte",
  "duurt",
  "duwde",
  "duwen",
  "dwaal",
  "dwaas",
  "dwang",
  "dwars",
  "dwaze",
  "dweep",
  "dweil",
  "dwerg",
  "dwing",
  "dwong",
  "ebben",
  "ebola",
  "echte",
  "edele",
  "edoch",
  "eerde",
  "eerst",
  "effen",
  "egaal",
  "egale",
  "egels",
  "eicel",
  "eigen",
  "eikel",
  "eiken",
  "einde",
  "eisen",
  "eiser",
  "eiste",
  "eitje",
  "eiwit",
  "eland",
  "elfde",
  "elfen",
  "elfje",
  "elite",
  "elpee",
  "elven",
  "elzen",
  "email",
  "emelt",
  "emirs",
  "emmer",
  "emoes",
  "emoji",
  "enfin",
  "engel",
  "enger",
  "engst",
  "engte",
  "enige",
  "enkel",
  "enorm",
  "enten",
  "enter",
  "enzym",
  "eraan",
  "erbij",
  "erfde",
  "erger",
  "ergst",
  "erker",
  "ermee",
  "ernst",
  "ertoe",
  "eruit",
  "ervan",
  "erven",
  "espen",
  "essay",
  "essen",
  "ester",
  "etage",
  "etend",
  "eters",
  "ether",
  "ethos",
  "etsen",
  "etste",
  "etter",
  "etude",
  "etuis",
  "euvel",
  "exact",
  "exoot",
  "expat",
  "extra",
  "ezels",
  "faalt",
  "fabel",
  "facet",
  "fagot",
  "fakir",
  "falen",
  "farao",
  "farce",
  "fasen",
  "fauna",
  "faxen",
  "faxte",
  "feeks",
  "feest",
  "felle",
  "felst",
  "ferme",
  "ferry",
  "fiche",
  "ficus",
  "fiets",
  "fijne",
  "fijns",
  "fikse",
  "files",
  "filet",
  "films",
  "filmt",
  "finse",
  "firma",
  "fitte",
  "fixen",
  "fjord",
  "flair",
  "flank",
  "flans",
  "flard",
  "flats",
  "flauw",
  "flens",
  "flets",
  "fleur",
  "flikt",
  "flink",
  "flipt",
  "flirt",
  "flits",
  "floep",
  "floot",
  "flopt",
  "flora",
  "flost",
  "fluim",
  "fluit",
  "fluks",
  "fluor",
  "flyer",
  "fnuik",
  "fobie",
  "focus",
  "fokte",
  "folie",
  "folio",
  "fonds",
  "fopte",
  "forel",
  "forum",
  "foton",
  "foute",
  "foyer",
  "fraai",
  "frame",
  "frans",
  "frase",
  "frats",
  "frees",
  "frets",
  "fries",
  "friet",
  "frist",
  "frons",
  "front",
  "fruit",
  "funky",
  "furie",
  "fusie",
  "fysio",
  "gaand",
  "gaapt",
  "gaard",
  "gaart",
  "gajes",
  "galei",
  "galmt",
  "galop",
  "gamba",
  "gamen",
  "gamer",
  "games",
  "gamma",
  "ganse",
  "gapen",
  "gaper",
  "garde",
  "garen",
  "gaten",
  "gaven",
  "gazon",
  "gebak",
  "gebed",
  "gebit",
  "gebod",
  "gedag",
  "gedij",
  "gedoe",
  "gedut",
  "geduw",
  "geeft",
  "geest",
  "geeuw",
  "gefit",
  "gegil",
  "gehad",
  "geile",
  "geilt",
  "gejat",
  "gekat",
  "gekef",
  "gekke",
  "gekko",
  "gekst",
  "gekte",
  "gelag",
  "gelal",
  "geldt",
  "gelei",
  "geler",
  "gelet",
  "gelid",
  "gelig",
  "gelik",
  "geluk",
  "gelul",
  "gemak",
  "gemis",
  "gemok",
  "gemor",
  "genas",
  "genen",
  "genie",
  "genot",
  "genre",
  "gents",
  "genus",
  "gepuf",
  "gered",
  "gerei",
  "geren",
  "gerot",
  "gerst",
  "gesar",
  "gesel",
  "gesis",
  "gesol",
  "gespt",
  "geste",
  "getal",
  "getij",
  "getik",
  "getob",
  "getto",
  "getut",
  "geuit",
  "geurt",
  "geval",
  "gevat",
  "gevel",
  "geven",
  "gever",
  "gewag",
  "gewas",
  "gewed",
  "gewei",
  "gewin",
  "gewis",
  "gewit",
  "gezag",
  "gezel",
  "gezet",
  "gezin",
  "gidst",
  "giert",
  "gifje",
  "gilde",
  "gilet",
  "ginds",
  "giraf",
  "glans",
  "gleed",
  "gleuf",
  "glimp",
  "glimt",
  "glipt",
  "globe",
  "gloed",
  "gloei",
  "glooi",
  "gluur",
  "gnoes",
  "gnoom",
  "goals",
  "goden",
  "godin",
  "goede",
  "goeds",
  "gokje",
  "gokte",
  "golem",
  "golft",
  "gonst",
  "gooit",
  "goten",
  "gouwe",
  "gozer",
  "graad",
  "graaf",
  "graag",
  "graai",
  "graal",
  "graan",
  "graas",
  "graat",
  "grapt",
  "grauw",
  "greep",
  "grens",
  "grief",
  "griek",
  "grien",
  "griep",
  "gries",
  "griet",
  "grijp",
  "grijs",
  "grill",
  "grilt",
  "grime",
  "grind",
  "grint",
  "grist",
  "groef",
  "groei",
  "groen",
  "groep",
  "groet",
  "gromt",
  "grond",
  "groot",
  "grote",
  "grove",
  "gruis",
  "gruwt",
  "guave",
  "gulle",
  "gumde",
  "gummi",
  "gunde",
  "gunst",
  "gutst",
  "gymde",
  "gyros",
  "haags",
  "haaks",
  "haakt",
  "haalt",
  "haard",
  "haast",
  "hackt",
  "hagel",
  "hagen",
  "haiku",
  "haken",
  "hakje",
  "hakte",
  "halal",
  "halen",
  "hallo",
  "halte",
  "halve",
  "hamer",
  "hands",
  "hanen",
  "hangt",
  "hanig",
  "haper",
  "hapje",
  "hapte",
  "harde",
  "hards",
  "harem",
  "haren",
  "harig",
  "harkt",
  "harte",
  "haten",
  "hater",
  "haven",
  "haver",
  "havik",
  "hazen",
  "hecht",
  "heden",
  "heeft",
  "heelt",
  "heers",
  "hegje",
  "heide",
  "heien",
  "heiig",
  "heisa",
  "hekel",
  "hekje",
  "helde",
  "helen",
  "heler",
  "helft",
  "helix",
  "helpt",
  "helse",
  "hemel",
  "henna",
  "heren",
  "hertz",
  "hesen",
  "hesje",
  "heten",
  "heter",
  "hetze",
  "heugt",
  "heult",
  "hevig",
  "hiaat",
  "hield",
  "hielp",
  "hijgt",
  "hijst",
  "hiken",
  "hikte",
  "hinde",
  "hindi",
  "hinkt",
  "hints",
  "hippe",
  "hitje",
  "hitte",
  "hobby",
  "hoede",
  "hoedt",
  "hoeft",
  "hoera",
  "hoest",
  "hoeve",
  "hoezo",
  "hofje",
  "hoger",
  "hokje",
  "holde",
  "holen",
  "holle",
  "holte",
  "holst",
  "holte",
  "honen",
  "hoofd",
  "hoont",
  "hoopt",
  "hoorn",
  "hoort",
  "hoost",
  "hopen",
  "horde",
  "horen",
  "horig",
  "hoste",
  "hotel",
  "houdt",
  "house",
  "houwt",
  "hoven",
  "hozen",
  "huilt",
  "huist",
  "hulde",
  "hulst",
  "humor",
  "humus",
  "hunne",
  "hupje",
  "huren",
  "hurkt",
  "husky",
  "hutje",
  "huurt",
  "huwde",
  "huwen",
  "hyena",
  "hymne",
  "hyper",
  "hypes",
  "icoon",
  "idool",
  "ieder",
  "iepen",
  "ieren",
  "ierse",
  "ijdel",
  "ijken",
  "ijkte",
  "ijlde",
  "ijlen",
  "ijsje",
  "ijver",
  "ijzel",
  "ijzer",
  "ijzig",
  "imago",
  "imams",
  "imker",
  "immer",
  "inbed",
  "inbox",
  "indam",
  "indek",
  "inden",
  "index",
  "india",
  "indik",
  "indut",
  "ineen",
  "inent",
  "inert",
  "inham",
  "inkom",
  "inleg",
  "inlog",
  "innam",
  "innen",
  "innig",
  "inpak",
  "inpik",
  "input",
  "inrit",
  "intro",
  "inuit",
  "inval",
  "invet",
  "invul",
  "inwin",
  "inzag",
  "inzak",
  "inzet",
  "inzie",
  "ionen",
  "islam",
  "issue",
  "items",
  "ivoor",
  "jaagt",
  "jacht",
  "jagen",
  "jager",
  "jamde",
  "jankt",
  "japan",
  "japon",
  "jaren",
  "jarig",
  "jasje",
  "jatte",
  "jawel",
  "jazzy",
  "jeans",
  "jeeps",
  "jemig",
  "jetje",
  "jeugd",
  "jeukt",
  "jicht",
  "jihad",
  "jodel",
  "joden",
  "jodin",
  "joelt",
  "jofel",
  "jogde",
  "joint",
  "joker",
  "jokte",
  "jolig",
  "jonge",
  "joods",
  "joule",
  "jouwe",
  "jouwt",
  "juich",
  "juist",
  "junks",
  "junta",
  "juten",
  "kaapt",
  "kaars",
  "kaart",
  "kaats",
  "kabel",
  "kader",
  "kades",
  "kadet",
  "kajak",
  "kakel",
  "kaken",
  "kaler",
  "kalme",
  "kamde",
  "kamer",
  "kampt",
  "kanen",
  "kanis",
  "kanon",
  "kapel",
  "kapen",
  "kaper",
  "kapje",
  "kapot",
  "kapte",
  "karaf",
  "karig",
  "karma",
  "karos",
  "kassa",
  "kasje",
  "kassa",
  "kaste",
  "kater",
  "katje",
  "katte",
  "kauwt",
  "kavel",
  "kazak",
  "kazen",
  "kebab",
  "keert",
  "kegel",
  "keien",
  "keilt",
  "keken",
  "kelen",
  "kenau",
  "kende",
  "keper",
  "kerel",
  "keren",
  "kerft",
  "kermt",
  "kerst",
  "ketel",
  "keten",
  "ketst",
  "keurt",
  "keuze",
  "kever",
  "kickt",
  "kiemt",
  "kiept",
  "kiest",
  "kieuw",
  "kijkt",
  "kille",
  "kilte",
  "kinds",
  "kinky",
  "kiosk",
  "kipje",
  "kirde",
  "klaag",
  "klaar",
  "klakt",
  "klamp",
  "klank",
  "klant",
  "klapt",
  "klare",
  "klauw",
  "kleed",
  "kleef",
  "klein",
  "klemt",
  "klerk",
  "klets",
  "kleum",
  "kleur",
  "kliek",
  "klier",
  "kliko",
  "klikt",
  "klimt",
  "kling",
  "klink",
  "kloek",
  "klojo",
  "klokt",
  "klomp",
  "klonk",
  "klont",
  "kloof",
  "klooi",
  "kloon",
  "kloot",
  "klopt",
  "klote",
  "klots",
  "kluif",
  "kluis",
  "kluit",
  "kluns",
  "klust",
  "kluts",
  "kluut",
  "knaag",
  "knaak",
  "knaap",
  "knakt",
  "knalt",
  "knapt",
  "knars",
  "knauw",
  "kneed",
  "kneep",
  "knelt",
  "kneus",
  "kniel",
  "knijp",
  "knikt",
  "knipt",
  "knoei",
  "knoet",
  "knokt",
  "knook",
  "knoop",
  "knort",
  "knots",
  "koala",
  "kocht",
  "koele",
  "koelt",
  "koene",
  "koers",
  "koert",
  "koest",
  "koets",
  "kogel",
  "koken",
  "koker",
  "koket",
  "kokos",
  "kolen",
  "kolft",
  "kolkt",
  "kolom",
  "kolos",
  "komaf",
  "komen",
  "komma",
  "komst",
  "konen",
  "kooit",
  "kookt",
  "koopt",
  "koord",
  "kopen",
  "koper",
  "kopie",
  "kopij",
  "kopje",
  "kopse",
  "kopte",
  "koran",
  "korea",
  "koren",
  "korps",
  "korst",
  "korte",
  "koter",
  "kotst",
  "koude",
  "kouds",
  "kozak",
  "kozen",
  "kraag",
  "kraai",
  "kraak",
  "kraal",
  "kraam",
  "kraan",
  "krabt",
  "krach",
  "krakt",
  "kramp",
  "krans",
  "krant",
  "krast",
  "kreeg",
  "kreek",
  "kreet",
  "kreng",
  "krent",
  "kreuk",
  "kreun",
  "kriek",
  "kriel",
  "krijg",
  "krijs",
  "krijt",
  "krikt",
  "krimp",
  "kring",
  "kroeg",
  "kroel",
  "kroep",
  "kroes",
  "krols",
  "kromp",
  "kromt",
  "kroon",
  "kroop",
  "kroos",
  "kropt",
  "kruid",
  "kruik",
  "kruim",
  "kruin",
  "kruip",
  "kruis",
  "kruit",
  "krult",
  "kubus",
  "kucht",
  "kudde",
  "kuier",
  "kuise",
  "kuist",
  "kunde",
  "kunst",
  "kuras",
  "kuren",
  "kusje",
  "kuste",
  "kutje",
  "kwaad",
  "kwaak",
  "kwaal",
  "kwade",
  "kwakt",
  "kwark",
  "kwart",
  "kwast",
  "kweek",
  "kwekt",
  "kwelt",
  "kwijl",
  "kwijn",
  "kwijt",
  "kwint",
  "laadt",
  "laaft",
  "laait",
  "laars",
  "label",
  "lacht",
  "laden",
  "lader",
  "lader",
  "lades",
  "laffe",
  "lafst",
  "lagen",
  "lager",
  "lakei",
  "laken",
  "lakse",
  "lakte",
  "lamel",
  "lamme",
  "lande",
  "landt",
  "lanen",
  "lange",
  "langs",
  "lapje",
  "lapte",
  "larie",
  "larve",
  "laser",
  "lasso",
  "laste",
  "latei",
  "laten",
  "later",
  "latex",
  "latje",
  "lauwe",
  "laven",
  "lazen",
  "lazer",
  "lease",
  "leden",
  "leder",
  "ledig",
  "leeft",
  "leegt",
  "leent",
  "leert",
  "leest",
  "leeuw",
  "legde",
  "legen",
  "leger",
  "leges",
  "legio",
  "leidt",
  "leien",
  "leken",
  "lekje",
  "lekke",
  "lekte",
  "lelie",
  "lemen",
  "lemma",
  "lende",
  "lenen",
  "lenig",
  "lente",
  "lepel",
  "lepra",
  "leren",
  "lesje",
  "lette",
  "leuke",
  "leuks",
  "leunt",
  "level",
  "leven",
  "lever",
  "lezen",
  "lezer",
  "liaan",
  "libel",
  "licht",
  "liefs",
  "liegt",
  "lieve",
  "lijdt",
  "lijkt",
  "lijmt",
  "lijnt",
  "lijpe",
  "lijst",
  "likje",
  "likte",
  "limbo",
  "limit",
  "linde",
  "linie",
  "linke",
  "links",
  "linkt",
  "lipje",
  "liter",
  "litho",
  "lobby",
  "loden",
  "loeit",
  "loens",
  "loert",
  "logee",
  "loges",
  "logge",
  "loket",
  "lokje",
  "lokte",
  "lolly",
  "lompe",
  "lonen",
  "lonkt",
  "loods",
  "looft",
  "looit",
  "loont",
  "loops",
  "loopt",
  "loost",
  "lopen",
  "loper",
  "loser",
  "losse",
  "loten",
  "lotto",
  "lotus",
  "loven",
  "lover",
  "lozen",
  "lucht",
  "luide",
  "luidt",
  "luier",
  "lukte",
  "lulde",
  "lullo",
  "lunch",
  "lurkt",
  "lusje",
  "luwen",
  "luwte",
  "luxer",
  "lycra",
  "lymfe",
  "maagd",
  "maait",
  "maakt",
  "maalt",
  "maand",
  "maant",
  "maart",
  "macho",
  "macht",
  "macro",
  "madam",
  "maden",
  "maffe",
  "magen",
  "mager",
  "magie",
  "magma",
  "mails",
  "mailt",
  "maken",
  "maker",
  "makke",
  "malen",
  "maler",
  "malle",
  "malse",
  "manco",
  "manen",
  "manga",
  "mango",
  "manie",
  "manke",
  "mapje",
  "marge",
  "markt",
  "massa",
  "match",
  "maten",
  "mater",
  "matig",
  "matje",
  "matst",
  "mazen",
  "meden",
  "media",
  "medio",
  "meent",
  "meest",
  "meeuw",
  "melde",
  "melkt",
  "melig",
  "menen",
  "mengt",
  "menie",
  "menig",
  "mensa",
  "mepte",
  "merel",
  "meren",
  "merkt",
  "mesje",
  "meten",
  "meter",
  "metro",
  "meurt",
  "meute",
  "mezen",
  "miauw",
  "micro",
  "mijdt",
  "mijne",
  "mijns",
  "mikte",
  "milde",
  "minor",
  "minst",
  "minus",
  "mirre",
  "miste",
  "mixen",
  "mixer",
  "mixte",
  "mocht",
  "model",
  "modem",
  "modes",
  "modus",
  "moest",
  "mogen",
  "moker",
  "mokka",
  "mokte",
  "molde",
  "molen",
  "monde",
  "mondt",
  "mooie",
  "moois",
  "moord",
  "mopje",
  "moren",
  "mores",
  "morse",
  "morst",
  "motel",
  "moten",
  "motie",
  "motje",
  "motor",
  "motto",
  "muffe",
  "mufst",
  "mugje",
  "muren",
  "murwe",
  "musea",
  "musje",
  "muzak",
  "muzen",
  "mythe",
  "naait",
  "naakt",
  "naald",
  "naast",
  "nabij",
  "nacho",
  "nacht",
  "nadat",
  "naden",
  "nader",
  "nadoe",
  "nagel",
  "nakie",
  "nakom",
  "namen",
  "nanny",
  "natie",
  "natje",
  "natst",
  "nauwe",
  "natte",
  "navel",
  "nawee",
  "nazit",
  "neder",
  "neemt",
  "negen",
  "neger",
  "neigt",
  "nekje",
  "nekte",
  "nemen",
  "nepte",
  "nerds",
  "nerts",
  "netel",
  "neten",
  "netje",
  "netst",
  "nette",
  "netto",
  "neukt",
  "neust",
  "nevel",
  "neven",
  "niche",
  "nicht",
  "niest",
  "niets",
  "nieuw",
  "nihil",
  "nijgt",
  "nijpt",
  "ninja",
  "nipje",
  "nipte",
  "nisje",
  "nobel",
  "noden",
  "nodig",
  "noemt",
  "noest",
  "nogal",
  "nooit",
  "noopt",
  "noord",
  "noors",
  "nopen",
  "noren",
  "norse",
  "noten",
  "notie",
  "nozem",
  "nurks",
  "nylon",
  "oasis",
  "obers",
  "octet",
  "odeur",
  "odium",
  "oefen",
  "oehoe",
  "oenen",
  "oenig",
  "oeros",
  "oever",
  "offer",
  "ofwel",
  "ogend",
  "okapi",
  "oksel",
  "olijf",
  "olijk",
  "olmen",
  "omarm",
  "omber",
  "omdat",
  "omdoe",
  "omduw",
  "omega",
  "omgaf",
  "omhak",
  "omhul",
  "omkom",
  "omleg",
  "omrij",
  "omrit",
  "omsla",
  "omval",
  "omvat",
  "omver",
  "omweg",
  "omzet",
  "onder",
  "onmin",
  "onsje",
  "ontga",
  "ontij",
  "onwel",
  "onwil",
  "onzin",
  "oogde",
  "oogje",
  "oogst",
  "ooien",
  "ootje",
  "opaal",
  "opbel",
  "opbod",
  "opdat",
  "opdoe",
  "opdof",
  "opeen",
  "opeet",
  "opeis",
  "opent",
  "opera",
  "opfok",
  "opgaf",
  "opheb",
  "ophef",
  "opium",
  "opjut",
  "opleg",
  "oplet",
  "oppak",
  "oppas",
  "opper",
  "oppik",
  "oppot",
  "oprek",
  "oprij",
  "oprit",
  "oprol",
  "oprot",
  "opruk",
  "opsla",
  "opsom",
  "opsta",
  "optel",
  "optie",
  "opval",
  "opwek",
  "opzag",
  "opzet",
  "opzie",
  "opzij",
  "oraal",
  "orale",
  "orden",
  "order",
  "oreer",
  "orgel",
  "orgie",
  "ossen",
  "otter",
  "ouden",
  "ouder",
  "oudje",
  "oudst",
  "ouwel",
  "ovaal",
  "ovale",
  "ovens",
  "oxide",
  "paait",
  "paalt",
  "paard",
  "paars",
  "paart",
  "pacht",
  "paddo",
  "padel",
  "paden",
  "padje",
  "pages",
  "pakje",
  "pakte",
  "palen",
  "palet",
  "palmt",
  "panda",
  "panel",
  "panna",
  "panty",
  "papil",
  "papje",
  "parel",
  "paren",
  "paria",
  "parka",
  "party",
  "pasen",
  "pasje",
  "pasta",
  "paste",
  "patat",
  "pater",
  "patio",
  "pauze",
  "pegel",
  "peilt",
  "peins",
  "pekel",
  "pelde",
  "penis",
  "peper",
  "peren",
  "perst",
  "pesto",
  "petje",
  "pezen",
  "pezig",
  "piano",
  "piekt",
  "piept",
  "piest",
  "pijpt",
  "piket",
  "pikte",
  "pilav",
  "pinda",
  "pinde",
  "pinkt",
  "pioen",
  "pipet",
  "piste",
  "pitch",
  "pitje",
  "pixel",
  "pizza",
  "plaag",
  "plaat",
  "plaid",
  "plakt",
  "plank",
  "plant",
  "plast",
  "plato",
  "plaza",
  "plebs",
  "pleeg",
  "plein",
  "pleit",
  "plemp",
  "pleng",
  "plens",
  "plets",
  "pleur",
  "plint",
  "ploeg",
  "ploft",
  "plomp",
  "plons",
  "plooi",
  "plopt",
  "plots",
  "pluim",
  "pluis",
  "plukt",
  "pocht",
  "podia",
  "poeha",
  "poema",
  "poept",
  "poert",
  "poets",
  "pofte",
  "pogen",
  "poken",
  "poker",
  "polen",
  "polio",
  "polis",
  "polka",
  "polls",
  "polst",
  "pompt",
  "poogt",
  "pookt",
  "poort",
  "popje",
  "porde",
  "porem",
  "porie",
  "porno",
  "porto",
  "poses",
  "poten",
  "potig",
  "potje",
  "poule",
  "pover",
  "praag",
  "praal",
  "praat",
  "prakt",
  "preek",
  "prees",
  "prent",
  "priem",
  "prijk",
  "prijs",
  "prikt",
  "prima",
  "prins",
  "print",
  "prive",
  "proef",
  "profs",
  "promo",
  "pronk",
  "prooi",
  "propt",
  "proxy",
  "proza",
  "pruik",
  "pruim",
  "pruts",
  "psalm",
  "puber",
  "pufje",
  "pufte",
  "puike",
  "puilt",
  "puist",
  "pulkt",
  "pumps",
  "punch",
  "punks",
  "pupil",
  "puppy",
  "puree",
  "putje",
  "puurs",
  "pylon",
  "quads",
  "quark",
  "quasi",
  "queer",
  "quilt",
  "quota",
  "quote",
  "raadt",
  "raakt",
  "raamt",
  "raapt",
  "raars",
  "raast",
  "rabbi",
  "racen",
  "racer",
  "races",
  "racet",
  "radar",
  "raden",
  "radio",
  "rafel",
  "ragen",
  "rages",
  "rails",
  "raken",
  "raket",
  "rally",
  "ramde",
  "ramen",
  "ramsj",
  "ranch",
  "ranja",
  "ranke",
  "rapen",
  "rappe",
  "rapst",
  "rapte",
  "raspt",
  "rasta",
  "ratel",
  "raten",
  "ratio",
  "ratje",
  "rauwe",
  "raven",
  "ravot",
  "rayon",
  "razen",
  "rebel",
  "rebus",
  "reces",
  "recht",
  "redde",
  "reden",
  "reder",
  "reeds",
  "reeks",
  "regel",
  "regen",
  "regie",
  "regio",
  "reilt",
  "reine",
  "reist",
  "rekel",
  "reken",
  "relax",
  "remde",
  "remix",
  "rende",
  "rente",
  "repen",
  "repte",
  "reset",
  "retro",
  "reuma",
  "reuze",
  "revue",
  "rezen",
  "riant",
  "ribbe",
  "richt",
  "riekt",
  "rijdt",
  "rijen",
  "rijgt",
  "rijke",
  "rijmt",
  "rijpe",
  "rijpt",
  "rijst",
  "rilde",
  "rinse",
  "riool",
  "riten",
  "rites",
  "ritje",
  "ritme",
  "robes",
  "robot",
  "rockt",
  "rodel",
  "rodeo",
  "roder",
  "roede",
  "roeit",
  "roemt",
  "roept",
  "roert",
  "roest",
  "rogge",
  "roken",
  "roker",
  "rokje",
  "rolde",
  "roman",
  "romen",
  "romig",
  "ronde",
  "ronkt",
  "roods",
  "rooft",
  "rooie",
  "rooit",
  "rookt",
  "rooms",
  "roomt",
  "rosse",
  "rotan",
  "rotje",
  "rotor",
  "rotte",
  "rouge",
  "route",
  "rouwt",
  "roven",
  "rover",
  "rozen",
  "rozet",
  "rozig",
  "ruche",
  "rugby",
  "ruige",
  "ruikt",
  "ruilt",
  "ruime",
  "ruimt",
  "ruist",
  "rukte",
  "rulle",
  "rulst",
  "rumba",
  "runde",
  "runen",
  "ruste",
  "ruwer",
  "ruzie",
  "saaie",
  "saais",
  "sabel",
  "sagen",
  "saldo",
  "salie",
  "salon",
  "salsa",
  "salto",
  "salut",
  "salvo",
  "samba",
  "samen",
  "sapje",
  "sarin",
  "satan",
  "sater",
  "sauna",
  "scala",
  "scalp",
  "scans",
  "scant",
  "scene",
  "schaf",
  "schal",
  "schap",
  "schar",
  "schat",
  "schel",
  "schep",
  "schik",
  "schil",
  "schim",
  "schip",
  "schok",
  "schol",
  "schop",
  "schor",
  "schot",
  "schub",
  "schud",
  "schut",
  "schuw",
  "scone",
  "scoop",
  "scoor",
  "scope",
  "score",
  "scout",
  "scrub",
  "scrum",
  "seder",
  "seint",
  "sekse",
  "sekte",
  "sepia",
  "serie",
  "serre",
  "serum",
  "sesam",
  "setje",
  "sfeer",
  "sfinx",
  "shake",
  "shawl",
  "shift",
  "shirt",
  "shock",
  "shops",
  "shopt",
  "short",
  "shots",
  "shows",
  "showt",
  "siert",
  "sifon",
  "sigma",
  "sikje",
  "sinas",
  "sinds",
  "sinti",
  "sinus",
  "sipst",
  "siste",
  "sitar",
  "sites",
  "sjaal",
  "sjans",
  "sjeik",
  "sjerp",
  "sjirp",
  "sjoel",
  "sjokt",
  "sjort",
  "sjouw",
  "skate",
  "skiet",
  "slaaf",
  "slaag",
  "slaak",
  "slaan",
  "slaap",
  "slaat",
  "slang",
  "slank",
  "slede",
  "sleep",
  "sleet",
  "sleuf",
  "sleur",
  "slibt",
  "sliep",
  "slijk",
  "slijm",
  "slijp",
  "slijt",
  "slikt",
  "slims",
  "slink",
  "slipt",
  "slist",
  "sloeg",
  "sloep",
  "sloft",
  "slokt",
  "slome",
  "sloof",
  "sloom",
  "sloop",
  "sloot",
  "sluik",
  "sluip",
  "sluis",
  "sluit",
  "slurf",
  "slurp",
  "sluwe",
  "smaad",
  "smaak",
  "smakt",
  "smart",
  "smash",
  "smeed",
  "smeek",
  "smeer",
  "smeet",
  "smelt",
  "smeul",
  "smijt",
  "smoel",
  "smoes",
  "smolt",
  "smoor",
  "smult",
  "smurf",
  "snaai",
  "snaak",
  "snaar",
  "snack",
  "snakt",
  "snapt",
  "snars",
  "snauw",
  "snede",
  "sneed",
  "sneer",
  "snelt",
  "snert",
  "sneue",
  "snikt",
  "snobs",
  "snode",
  "snoei",
  "snoek",
  "snoep",
  "snoer",
  "snoes",
  "snoet",
  "snood",
  "snoof",
  "snoot",
  "snort",
  "snuft",
  "snuif",
  "snuit",
  "snurk",
  "soaps",
  "sober",
  "soeps",
  "soera",
  "soesa",
  "softe",
  "sokje",
  "solde",
  "somma",
  "sonar",
  "sonde",
  "songs",
  "soort",
  "sopje",
  "sopte",
  "sores",
  "sorry",
  "spaak",
  "spaan",
  "spaar",
  "spade",
  "spalk",
  "spant",
  "speed",
  "speel",
  "speen",
  "speer",
  "speet",
  "spekt",
  "speld",
  "spelt",
  "spert",
  "speur",
  "spiek",
  "spier",
  "spies",
  "spijl",
  "spijs",
  "spijt",
  "spike",
  "spilt",
  "spint",
  "spion",
  "spits",
  "split",
  "spoed",
  "spoel",
  "spons",
  "spook",
  "spoor",
  "spoot",
  "sport",
  "spouw",
  "sprak",
  "spray",
  "sprei",
  "sprot",
  "spruw",
  "spuit",
  "spurt",
  "spuug",
  "spuwt",
  "staaf",
  "staak",
  "staal",
  "staan",
  "staar",
  "staat",
  "stads",
  "stage",
  "stalk",
  "stalt",
  "stamp",
  "stamt",
  "stand",
  "stang",
  "stank",
  "stapt",
  "stars",
  "start",
  "steak",
  "steeg",
  "steek",
  "steel",
  "steen",
  "steil",
  "stekt",
  "stelp",
  "stelt",
  "stemt",
  "steno",
  "stept",
  "sterf",
  "sterk",
  "stern",
  "steun",
  "steur",
  "stick",
  "stiel",
  "stier",
  "stift",
  "stijf",
  "stijg",
  "stijl",
  "stikt",
  "stilt",
  "stink",
  "stipt",
  "stoei",
  "stoel",
  "stoep",
  "stoer",
  "stoet",
  "stoft",
  "stokt",
  "stola",
  "stolp",
  "stolt",
  "stoma",
  "stomp",
  "stoms",
  "stond",
  "stonk",
  "stoof",
  "stook",
  "stoom",
  "stoor",
  "stoot",
  "stops",
  "stopt",
  "storm",
  "stort",
  "stout",
  "straf",
  "strak",
  "stram",
  "strek",
  "strik",
  "strip",
  "strop",
  "strot",
  "stuif",
  "stuik",
  "stuip",
  "stuit",
  "stuks",
  "stulp",
  "stunt",
  "stuur",
  "stuwt",
  "style",
  "suede",
  "suffe",
  "sufte",
  "suist",
  "suite",
  "sujet",
  "super",
  "surft",
  "sushi",
  "suste",
  "swing",
  "taaie",
  "taalt",
  "taant",
  "taart",
  "taats",
  "tabak",
  "tabee",
  "tabel",
  "taboe",
  "tafel",
  "tahin",
  "tahoe",
  "takel",
  "taken",
  "takje",
  "talen",
  "talig",
  "talmt",
  "talud",
  "tamme",
  "tango",
  "tanig",
  "tanks",
  "tankt",
  "tante",
  "tapas",
  "tapen",
  "tapes",
  "tapet",
  "tapir",
  "tarot",
  "tarwe",
  "tasje",
  "taupe",
  "taxus",
  "teams",
  "teder",
  "teelt",
  "teert",
  "tegel",
  "tegen",
  "teint",
  "teken",
  "tekst",
  "telde",
  "telen",
  "teler",
  "telex",
  "temde",
  "tempo",
  "tenen",
  "tenor",
  "tenue",
  "tepel",
  "teren",
  "tergt",
  "terra",
  "terts",
  "terug",
  "tests",
  "thais",
  "thans",
  "thema",
  "these",
  "thora",
  "thuis",
  "tiara",
  "tiert",
  "tikje",
  "tikte",
  "tilde",
  "timer",
  "tipje",
  "tipte",
  "tiran",
  "titel",
  "tjalk",
  "tjilp",
  "tjirp",
  "toast",
  "tobbe",
  "tobde",
  "tocht",
  "toert",
  "toets",
  "toffe",
  "togen",
  "tolde",
  "tolkt",
  "tombe",
  "tonen",
  "toner",
  "tonic",
  "tonus",
  "tooit",
  "toont",
  "toorn",
  "toost",
  "topje",
  "toque",
  "toren",
  "tornt",
  "torso",
  "toste",
  "tosti",
  "totem",
  "tours",
  "tover",
  "traag",
  "traan",
  "track",
  "trage",
  "train",
  "trams",
  "trans",
  "trant",
  "trapt",
  "trede",
  "treed",
  "treft",
  "trein",
  "trekt",
  "trema",
  "trend",
  "trens",
  "treur",
  "trilt",
  "trimt",
  "trips",
  "tript",
  "trits",
  "troef",
  "troep",
  "troon",
  "trots",
  "trouw",
  "truck",
  "trucs",
  "trust",
  "tsaar",
  "tubes",
  "tucht",
  "tufte",
  "tukje",
  "tumor",
  "tuner",
  "turbo",
  "turen",
  "turft",
  "turks",
  "turnt",
  "tutje",
  "tutor",
  "tuurt",
  "tweed",
  "twijg",
  "twist",
  "tyfus",
  "typen",
  "types",
  "typte",
  "uiers",
  "uilen",
  "uiten",
  "uitga",
  "uitje",
  "uitte",
  "uiver",
  "ultra",
  "uniek",
  "units",
  "urine",
  "urnen",
  "uzelf",
  "vaags",
  "vaars",
  "vaart",
  "vacht",
  "vadem",
  "vader",
  "vager",
  "vaker",
  "vakje",
  "valer",
  "valse",
  "vanaf",
  "vanen",
  "vangt",
  "varen",
  "varia",
  "vaste",
  "vaten",
  "vatte",
  "vazal",
  "vazen",
  "vecht",
  "veder",
  "veegt",
  "veert",
  "vegen",
  "veger",
  "veilt",
  "veins",
  "velde",
  "velen",
  "veraf",
  "veren",
  "verft",
  "verga",
  "vergt",
  "verre",
  "verse",
  "verst",
  "verte",
  "veste",
  "veter",
  "vetes",
  "vetst",
  "vette",
  "vezel",
  "video",
  "vides",
  "viert",
  "viest",
  "vieze",
  "vijlt",
  "vilde",
  "villa",
  "vindt",
  "vinkt",
  "vinyl",
  "viola",
  "viool",
  "virus",
  "visie",
  "visje",
  "viste",
  "visum",
  "vlaag",
  "vlaai",
  "vlagt",
  "vlamt",
  "vlees",
  "vleet",
  "vleit",
  "vlekt",
  "vlerk",
  "vleug",
  "vlieg",
  "vlier",
  "vlies",
  "vliet",
  "vlijt",
  "vloed",
  "vloei",
  "vloek",
  "vloer",
  "vlogt",
  "vloog",
  "vlooi",
  "vloot",
  "vocht",
  "vodde",
  "vodje",
  "voedt",
  "voegt",
  "voelt",
  "voert",
  "vogel",
  "volgt",
  "volks",
  "volle",
  "volop",
  "volta",
  "volts",
  "vonkt",
  "voogd",
  "voorn",
  "voort",
  "voren",
  "vorig",
  "vormt",
  "vorst",
  "vosje",
  "vouwt",
  "vozen",
  "vraag",
  "vraat",
  "vrede",
  "vrees",
  "vreet",
  "vreze",
  "vries",
  "vrije",
  "vrijt",
  "vrind",
  "vroeg",
  "vrome",
  "vroom",
  "vroor",
  "vrouw",
  "vuige",
  "vuile",
  "vuist",
  "vulde",
  "vulva",
  "vuren",
  "vurig",
  "vuurt",
  "waadt",
  "waagt",
  "waait",
  "waaks",
  "waakt",
  "waant",
  "waard",
  "waart",
  "wacht",
  "waden",
  "wafel",
  "wagen",
  "wagon",
  "waken",
  "waker",
  "walen",
  "walgt",
  "walmt",
  "walst",
  "wanen",
  "wants",
  "wapen",
  "waren",
  "warme",
  "warmt",
  "wasco",
  "wasem",
  "wasje",
  "waste",
  "water",
  "watje",
  "wazig",
  "weder",
  "weeft",
  "weegt",
  "weekt",
  "weent",
  "weert",
  "wegen",
  "wegga",
  "weide",
  "weids",
  "weidt",
  "weken",
  "wekte",
  "welde",
  "welig",
  "welke",
  "welnu",
  "wende",
  "wendt",
  "wenen",
  "wenkt",
  "wenst",
  "weren",
  "werft",
  "werkt",
  "werpt",
  "weten",
  "weven",
  "wever",
  "wezel",
  "wezen",
  "wicht",
  "wiedt",
  "wiegt",
  "wiens",
  "wierp",
  "wijde",
  "wijds",
  "wijdt",
  "wijkt",
  "wijst",
  "wijze",
  "wikte",
  "wilde",
  "wilds",
  "windt",
  "winst",
  "wipte",
  "wisse",
  "witje",
  "witst",
  "witte",
  "wodka",
  "woede",
  "woedt",
  "woelt",
  "woerd",
  "woest",
  "wogen",
  "wonen",
  "woont",
  "woord",
  "wordt",
  "worst",
  "wraak",
  "wrang",
  "wraps",
  "wrede",
  "wreed",
  "wreef",
  "wreek",
  "wrijf",
  "wrikt",
  "wring",
  "wroet",
  "wrong",
  "wuift",
  "wulps",
  "wurgt",
  "zaagt",
  "zaait",
  "zacht",
  "zadel",
  "zaden",
  "zagen",
  "zaken",
  "zakje",
  "zakte",
  "zalen",
  "zalft",
  "zalig",
  "zanik",
  "zapte",
  "zaten",
  "zatte",
  "zebra",
  "zeden",
  "zedig",
  "zeeft",
  "zeemt",
  "zeept",
  "zeeuw",
  "zegel",
  "zegen",
  "zegje",
  "zeikt",
  "zeilt",
  "zeken",
  "zeker",
  "zelfs",
  "zelve",
  "zemel",
  "zemen",
  "zendt",
  "zenuw",
  "zepen",
  "zepig",
  "zesde",
  "zesje",
  "zetel",
  "zetje",
  "zette",
  "zeult",
  "zeurt",
  "zeven",
  "zicht",
  "zieke",
  "ziekt",
  "ziezo",
  "zijde",
  "zijig",
  "zijne",
  "zilte",
  "zinde",
  "zingt",
  "zinkt",
  "zitje",
  "zoals",
  "zocht",
  "zodat",
  "zoden",
  "zodra",
  "zoeft",
  "zoekt",
  "zoemt",
  "zoent",
  "zoete",
  "zoets",
  "zogen",
  "zolen",
  "zomen",
  "zomer",
  "zomin",
  "zonde",
  "zonen",
  "zones",
  "zonet",
  "zoogt",
  "zoomt",
  "zoons",
  "zopen",
  "zorgt",
  "zotte",
  "zoute",
  "zover",
  "zowat",
  "zowel",
  "zucht",
  "zuigt",
  "zuipt",
  "zulke",
  "zulks",
  "zuren",
  "zurig",
  "zusje",
  "zwaai",
  "zwaan",
  "zwaar",
  "zwalk",
  "zwang",
  "zwans",
  "zware",
  "zwart",
  "zweed",
  "zweef",
  "zweeg",
  "zweem",
  "zweep",
  "zweer",
  "zweet",
  "zwelg",
  "zwelt",
  "zwemt",
  "zwenk",
  "zwerf",
  "zwerk",
  "zwerm",
  "zwets",
  "zwiep",
  "zwier",
  "zwijg",
  "zwijm",
  "zwijn",
  "zwikt",
  "zwoeg",
  "zwoel",
  "zwoer",
  "zwolg",
];
