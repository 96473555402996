export const blacklist = [
    "neger",
    "vulva",
    "penis",
    "joden",
    "porno",
    "klote",
    "tyfus",
    "kutje",
    "neukt",
    "zuigt",
    "kloot",
    "soesa",
    "zwans",
    "anaal",
    "anale",
    "lijpe",
    "geilt",
    "queer",
    "dildo",
    "gelul",
    "lulde",
    "lullo",
    "pijpt",
    "gifje",
]